/// React
import React from "react";
// Layouts
import { LayoutProps } from "@layouts/types";
// Components
import { Nav } from "@components/nav";

export const DefaultLayout = ({ children }: LayoutProps): React.ReactElement => (
  <main className="container">
    <Nav />

    {children}
  </main>
);
