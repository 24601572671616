/**
 * TODO: BOILERPLATE - remove as it is just an example / demo / template component
 */

// Styles
import "./index.scss";
/// React
import { generatePath, useNavigate, useParams } from "react-router-dom";
import React from "react";
// Routes
import { allRoutes } from "@routes/index";
import { getRoute } from "@routes/utils/get-route";
// Router

export const PreviewMenu = (): React.ReactElement => {
  const params = useParams();
  const navigate = useNavigate();

  const links = React.useMemo(
    () => [
      {
        to: generatePath(getRoute("env"), params),
        label: allRoutes.env.name,
        redirect: () => navigate(generatePath(getRoute("env"), params), { replace: true }),
      },
      {
        to: generatePath(getRoute("packageData"), params),
        label: allRoutes.packageData.name,
        redirect: () => navigate(generatePath(getRoute("packageData"), params), { replace: true }),
      },
      {
        to: generatePath(getRoute("auth"), params),
        label: allRoutes.auth.name,
        redirect: () => navigate(generatePath(getRoute("auth"), params), { replace: true }),
      },
    ],
    [navigate, params]
  );

  return (
    <nav className="preview-menu">
      {links.map((i) => (
        <div key={i.to} className="preview-menu__item" onClick={i.redirect}>
          {i.label}

          <iframe className="preview-menu__iframe" src={i.to} scrolling="no" />
        </div>
      ))}
    </nav>
  );
};
