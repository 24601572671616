// React
import React from "react";
// Translations
import { lang } from "@lang/index";
// Router
import { View } from "@routes/index";
// Layouts
import { DefaultLayout } from "@layouts/Default";

const Element = (): React.ReactElement => (
  <DefaultLayout>
    <h1>{lang("ENV_VIEW_TITLE")}</h1>
    <p>{lang("ENV_VIEW_DESCRIPTION")}</p>

    <code>
      {JSON.stringify(Object.fromEntries(Object.entries(process.env).reverse()), null, 2)}
    </code>
  </DefaultLayout>
);

export const env: View = {
  name: "Env",
  options: {
    path: "/env",
    element: <Element />,
  },
};
