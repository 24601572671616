// React
import React from "react";
// Translations
import { lang } from "@lang/index";
// Router
import { View } from "@routes/index";
// Layouts
import { DefaultLayout } from "@layouts/Default";
// Package Info
import PackageJson from "../../../package.json";

const Element = (): React.ReactElement => (
  <DefaultLayout>
    <h1>{lang("PACKAGE_VIEW_TITLE")}</h1>
    <p>{lang("PACKAGE_VIEW_DESCRIPTION")}</p>

    <code>{JSON.stringify(PackageJson, null, 2)}</code>
  </DefaultLayout>
);

export const packageData: View = {
  name: "Package",
  options: {
    path: "/package",
    element: <Element />,
  },
};
