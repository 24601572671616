/**
 * TODO: BOILERPLATE - remove as it is just an example / demo / template component
 */

// Styles
import "./index.scss";
/// React
import { NavLink, useParams } from "react-router-dom";
import React from "react";
import { generatePath } from "react-router";
// Routes
import { allRoutes } from "@routes/index";
import { getRoute } from "@routes/utils/get-route";

export const Nav = (): React.ReactElement => {
  const params = useParams();

  const links = React.useMemo(
    () => [
      {
        to: generatePath(getRoute("home"), params),
        label: allRoutes.home.name,
      },
      {
        to: generatePath(getRoute("env"), params),
        label: allRoutes.env.name,
      },
      {
        to: generatePath(getRoute("packageData"), params),
        label: allRoutes.packageData.name,
      },
      {
        to: generatePath(getRoute("auth"), params),
        label: allRoutes.auth.name,
      },
    ],
    [params]
  );

  return (
    <nav className="nav">
      {links.map((i) => (
        <NavLink
          key={i.to}
          className={({ isActive }): string =>
            ["nav__link", ...(isActive ? ["is-active"] : [])].join(" ")
          }
          to={i.to}
        >
          {i.label}
        </NavLink>
      ))}
    </nav>
  );
};
