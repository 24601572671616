// React
import React from "react";
// Translations
import { lang } from "@lang/index";
// Router
import { View } from "@routes/index";
// Layouts
import { ErrorLayout } from "@layouts/Error";

const Element = (): React.ReactElement => (
  <ErrorLayout
    title={lang("ERROR_PAGE_GENERIC_TITLE")}
    description={lang("ERROR_PAGE_GENERIC_DESCRIPTION")}
  />
);

export const errorGeneric: View = {
  name: "Error",
  options: {
    path: "/error",
    element: <Element />,
  },
};
