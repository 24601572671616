// Styles
import "./styles/index.scss";
// React
import React, { StrictMode, useMemo } from "react";
// Router
import { DefaultRouter } from "@routes/router/Default";
// Utils
import { ErrorBoundary } from "@advicefront/fe-infra-error-and-logging";
import { GlobalState } from "@advicefront/fe-infra-global-state";
import { UseAuthenticatedProps } from "@advicefront/fe-infra-auth";
// Context
import { AppOptionsContext, AppOptionsContextProps } from "@context/app-options";
// Translations
import { lang } from "@lang/index";
// Layouts
import { ErrorLayout } from "@layouts/Error";

const App = (): React.ReactElement => {
  const appOptions = useMemo<AppOptionsContextProps>(
    () => ({
      // used directly from .env, exposed by dev-env
      demoApiHost: GlobalState.get<string>("api-host", {
        requireDefined: true,
      }),
      // created by webpack through local definition, exposed by dev-env
      routerBaseName: GlobalState.get<string>("router-basename"),
      // created by dev-env package through webpack package
      getAuthToken: GlobalState.get<UseAuthenticatedProps["getAuthToken"]>("get-auth-token", {
        requireDefined: true,
      }),
    }),
    []
  );

  return (
    <AppOptionsContext.Provider value={appOptions}>
      <DefaultRouter />
    </AppOptionsContext.Provider>
  );
};

export const AppWrapper = (): React.ReactElement => (
  <StrictMode>
    <ErrorBoundary
      Component={(e): React.ReactElement => (
        <ErrorLayout
          title={lang("ERROR_PAGE_GENERIC_TITLE")}
          description={
            <>
              {lang("ERROR_PAGE_GENERIC_DESCRIPTION")}
              <br />
              <br />
              <u>{e.message}</u>
            </>
          }
        />
      )}
    >
      <App />
    </ErrorBoundary>
  </StrictMode>
);
