// React
import React from "react";
// Utils
import { createRoot, hydrateRoot } from "react-dom/client";
// Config
import * as appConfig from "./config-client";
// App
import { AppWrapper } from "./App";
// Package info
import packageJson from "../package.json";

const container = ((): HTMLElement => {
  const el = document.getElementById(appConfig.ROOT_ELEMENT_ID);
  if (!el)
    throw new Error(`${packageJson.name} - Missing root element (#${appConfig.ROOT_ELEMENT_ID})`);
  return el;
})();

if (container.hasChildNodes()) {
  hydrateRoot(container, <AppWrapper />);
} else {
  createRoot(container).render(<AppWrapper />);
}

// Debug stuff

document.body.appendChild(
  document.createComment(
    JSON.stringify(Object.fromEntries(Object.entries(process.env).reverse()), null, 2)
  )
);
document.body.appendChild(document.createComment(JSON.stringify(packageJson, null, 2)));
