// React
import React from "react";
// Translations
import { lang } from "@lang/index";
// Router
import { View } from "@routes/index";
// Layouts
import { DefaultLayout } from "@layouts/Default";
// Auth
import { useAuthHandler } from "@hooks/auth-handler";

const Element = (): React.ReactElement => {
  const data = useAuthHandler();

  return (
    <DefaultLayout>
      <h1>{lang("AUTH_VIEW_TITLE")}</h1>
      <p>{lang("AUTH_VIEW_DESCRIPTION")}</p>

      <code>{JSON.stringify(data, null, 2)}</code>
    </DefaultLayout>
  );
};

export const auth: View = {
  name: "Auth",
  options: {
    path: "/auth",
    element: <Element />,
  },
};
