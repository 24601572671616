// React
import React from "react";
// Translations
import { lang } from "@lang/index";
// Router
import { View } from "@routes/index";
// Layouts
import { BlankLayout } from "@layouts/Blank";
// Components
import { PreviewMenu } from "@components/preview-menu";

const Element = (): React.ReactElement => (
  <BlankLayout>
    <h1>{lang("HOME_VIEW_TITLE")}</h1>
    <p>{lang("HOME_VIEW_DESCRIPTION")}</p>

    <PreviewMenu />
  </BlankLayout>
);

export const home: View = {
  name: "Home",
  options: {
    path: "/",
    element: <Element />,
  },
};
