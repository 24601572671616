/**
 * TODO: BOILERPLATE - adapt context props to your app's need
 * or remove this context & references if your app does not need
 * any context options
 */

/**
 * App (entry) options, options with no visual/UI
 * responsibility should be defined here.
 * (MFE) init options of the same scope should be included here
 */

// React
import React, { useContext } from "react";
// Types
import { UseAuthenticatedProps } from "@advicefront/fe-infra-auth";

export interface AppOptionsContextProps {
  demoApiHost?: string;
  routerBaseName?: string;
  getAuthToken: UseAuthenticatedProps["getAuthToken"];
}

export const AppOptionsContextDefaults: AppOptionsContextProps = {
  demoApiHost: undefined,
  routerBaseName: undefined,
  getAuthToken: () => Promise.resolve(undefined),
};

export const AppOptionsContext =
  React.createContext<AppOptionsContextProps>(AppOptionsContextDefaults);

export const useAppOptionsContext = (): AppOptionsContextProps => useContext(AppOptionsContext);
