// React
import React from "react";
// Translations
import { lang } from "@lang/index";
// Router
import { View } from "@routes/index";
// Layouts
import { ErrorLayout } from "@layouts/Error";

const Element = (): React.ReactElement => (
  <ErrorLayout
    title={lang("ERROR_PAGE_AUTH_TITLE")}
    description={lang("ERROR_PAGE_AUTH_DESCRIPTION")}
  />
);

export const errorAuth: View = {
  name: "Auth Error",
  options: {
    path: "/auth-error",
    element: <Element />,
  },
};
