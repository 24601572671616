// React
import { NavLink } from "react-router-dom";
import React from "react";
// Translations
import { lang } from "@lang/index";
// Router
import { View } from "@routes/index";
// Layouts
import { ErrorLayout } from "@layouts/Error";

const Element = (): React.ReactElement => (
  <ErrorLayout
    title={lang("ERROR_PAGE_NOT_FOUND_TITLE")}
    description={lang("ERROR_PAGE_NOT_FOUND_DESCRIPTION")}
  >
    <NavLink to="/">Back to initial page</NavLink>
  </ErrorLayout>
);

export const error404: View = {
  name: "Error",
  options: {
    path: "*",
    element: <Element />,
  },
};
