// React
import React from "react";
// Router
import { View } from "@routes/index";
// Utils
import { ViewRedirect } from "@routes/utils/ViewRedirect";
// Views
import * as Views from "@views/index";

export const homeRedirect: View = {
  name: "Home redirect",
  options: {
    path: "/home",
    element: <ViewRedirect route={Views.home} />,
  },
};
